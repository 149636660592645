import React from 'react';
import {Helmet} from 'react-helmet';
import _ from 'lodash';

import '../sass/main.scss';
import Header from './Header';
import Footer from './Footer';

export default class Body extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>{_.get(this.props, 'pageContext.frontmatter.title', null) && (_.get(this.props, 'pageContext.frontmatter.title', null) + ' - ')}{_.get(this.props, 'pageContext.site.siteMetadata.title', null)}</title>
                    <meta charSet="utf-8"/>
                    <meta name="viewport" content="width=device-width, initialScale=1.0" />
                    <meta name="google" content="notranslate" />
                    <link href="https://fonts.googleapis.com/css?family=Nunito+Sans:400,400i,700,700i" rel="stylesheet"/>    
                    <meta property="og:title" content="PreSetGo - Free Motor, langauge and cognitive screenings for 3-5 year olds" />
                    <meta property="og:description" content="PreSetGo aspires to help parents uncover the skills and needs of their 3-5 year old child, and to facilitate the creation of an able and content kindergartener. " />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://www.presetgo.com/" />
                    <meta property="og:image" content="https://www.presetgo.com/images/fb-ogimage1.jpg" />
                </Helmet>
                <div id="page" className={'site palette-' + _.get(this.props, 'pageContext.site.siteMetadata.palette', null)}>
                  <Header {...this.props} />
                  <main id="content" className="site-content">
                    {this.props.children}
                  </main>
                  <Footer {...this.props} />
                </div>
            </React.Fragment>
        );
    }
}
